import styled, { css } from 'styled-components';

const liveIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIoSURBVHgB7VRNdhJBEP56hsSn2UxWyg5u0K598OAETk5gOEHwBCEn0JwAPIF6AkZxT+cEzEoSN7AT8tLTftUZNUxmQhZZZJF6r6merr/+vioaeJJHKfOObsh6CP+g7FBlGCuL8ayjI2wR8fH+GT7fq8BFWx/DocFt2kzMElvE+zikXHre1oOiXd38WPA26wxTKeBCNJ2FXodIqgoJLeKjQpjAYup49ptxN/03EKwsYknOqiP5DoHhc1sO3QdbfGCCoewzhVOqiP6HGz4FOG+9DnHK4GPeKHIO3+TsV1sfnbf1jGs6b2mfJHM4k6Ts1xAZEn8GdAo5/4skIILoGWGuLRY8Sl9NTPOipWMWEyQJA5bcxyzcrf8wCW0LuQip2X9hMeN+KTGlCPLmmhWgc9hfcx1LcgZ2X07MgewDhXdiswqfRO+BsYrNhs9RTpGHpBDZS2ydnqLYK0/nrbjaZnakdGrs7SIlRQicv2WfxI5oG5OO4TVQdMh1L7+h79uqBkOKNG1pNQIHw99odeUpSmR/3tJ94ZqFT1hEO8UmZnhfn5jR3/+MTN3OJUeb/ix8Vo0AfmJilu24AD0/29dcf2SRAfWg4B9L07MQJzvwUydT86USAadnJAHK4Sg/6rHQASpkN0DX5lQx+SFVKshwl/x8o/ukxXGNcU8RXx/D2KJNVQVQyXPRrScmxR3y73mR+f9uXhfttbIgJvawtyUX2ee7wzepiyd5tPIHLBbxzjpSxe4AAAAASUVORK5CYII=';

const calendarIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgB7ZMxTsQwFETnO64CQtaygkU0LleCgiOEG3AEbgB0dFwBbsIN4Ag0SHS42xUFcoFQio0/TgQoIbHJioLVKq+c8fz5shNg4L9JYqbSR2ozHV2mG7t4f3sxdW88OczSrfHpzvb+g7XzPDRDxAqQ54oZVxAuayddVnrWn4mNoJ/CaO/gnMBnNUmDyILZNpOkvFYON18Sg25eZ4/XjT1ai1EV0jXJtIZX0yrN1Bf5zDaQCFCwO7bzJ4MeqMlUJySeu7xggZRSKz1FH+RCai5ctxcK+cBdUsS/ge+zcEEvWOBf/5YJFv0ayrs/Wapgwe7CzpZ6g86CfnfwB4aCX1nH/8CJexLhQBSfxcDK8QE6JF6XhAnpCAAAAABJRU5ErkJggg==';

export const FeaturedEventsCardWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 100%;
    margin-top: ${theme.spacings.sm};

    a {
      width: 23rem;
      display: inline-block;
      border-radius: ${theme.borderRadius};
      background: ${theme.colors.base['50']};
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.sm};
    height: 2.4rem;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid ${theme.colors.base['200']};

    span {
      display: block;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 6.4rem;
    gap: ${theme.spacings.sm};
    padding: 0 ${theme.spacings.sm};
  `}
`;

export const Time = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-width: 2.4rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .featured-events__live {
      gap: 0.2px;
      display: flex;
      text-align: center;
      flex-direction: column;
      color: ${theme.colors.actions.live};

      &::before {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        margin: 0 auto;
        background-size: contain;
        background-image: url(${liveIcon});
        animation: liveScale 2s infinite;
      }

      @keyframes liveScale {
        0% {
          transform: scale(0.6);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.6);
        }
      }
    }

    .featured-events__date {
      gap: 0.2px;
      display: flex;
      text-align: center;
      flex-direction: column;

      &::before {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        margin: 0 auto 0.2rem;
        background-size: contain;
        background-image: url(${calendarIcon});
      }
    }
  `}
`;

export const Teams = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacings.xs};
  `}

  .team__name {
    overflow: hidden;
    max-width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Team = styled.div`
  gap: 0.8rem;
  display: flex;
`;

export const Scoreboard = styled.div`
  ${({ theme }) => css`
    width: 2.4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacings.xs};

    span {
      &.featured-events__live-points {
        color: ${theme.colors.actions.live};
      }
    }
  `}
`;
