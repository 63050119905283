import { useState, useEffect } from 'react';

const placeholderImage = '/images/placeholder.jpg';

const useThumbnailImage = thumbnailUrl => {
  const [imgSrc, setImgSrc] = useState(thumbnailUrl || placeholderImage);

  useEffect(() => {
    setImgSrc(thumbnailUrl || placeholderImage);
  }, [thumbnailUrl]);

  const handleImageError = () => {
    setImgSrc(placeholderImage);
  };

  return [imgSrc, handleImageError];
};

export default useThumbnailImage;
