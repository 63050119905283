import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.locale('pt-br');

export const formatRelativeTime = date => (date ? dayjs(date).fromNow() : '');

export const formatDate = date =>
  date ? dayjs(date).format('DD/MM/YYYY [às] HH[h]mm') : '';
