import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Pagination, Navigation, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import spacings from 'theme/spacings';
import Text from 'components/foundation/Text';
import { size } from 'theme/utils/functions';
import Grid from 'components/foundation/layout/Grid';

import * as S from './styles';
import 'swiper/swiper-bundle.css';
import 'react-loading-skeleton/dist/skeleton.css';

const WidgetShorts = ({ className, limit = 5, youtubeShorts = [] }) => {
  const placeholderImage = '/images/placeholder.jpg';
  const limitedShorts = youtubeShorts?.slice?.(0, limit);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeModal, setActiveModal] = useState(false);

  const hideModal = () => {
    setActiveModal(false);
  };

  const showModal = index => {
    setActiveModal(true);
    setCurrentSlide(index);
  };

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        hideModal();
      }
    };

    if (activeModal) {
      document.body.classList.add('hidden');
      window.addEventListener('keydown', handleEsc);
    } else {
      document.body.classList.remove('hidden');
      window.removeEventListener('keydown', handleEsc);
    }

    return () => {
      document.body.classList.remove('hidden');
      window.removeEventListener('keydown', handleEsc);
    };
  }, [activeModal]);

  const skeletonTemplate = Array.from({ length: 5 }).map((_, index) => (
    <Skeleton
      key={index}
      width={108}
      height={192}
      style={{ marginRight: spacings.xs }}
    />
  ));

  return (
    <S.WidgetShortsWrapper className={className}>
      <Text
        as='h4'
        $variant={{
          typography: 'heading4',
        }}
        $csscolor='600'
      >
        Últimos Arena Shorts
      </Text>

      <Swiper
        grabCursor
        speed={500}
        spaceBetween={4}
        slidesPerView='auto'
        className='widget-shorts__slide'
      >
        {limitedShorts?.map((item, index) => {
          const image =
            item?.thumbnails?.standard?.url ||
            item?.thumbnails?.high?.url ||
            placeholderImage;

          return index < limit ? (
            <SwiperSlide
              key={item?.resourceId?.videoId}
              className='widget-shorts__slide-item'
              onClick={() => showModal(index)}
            >
              <S.SlideContent slideImage={image}>
                <Text
                  as='p'
                  $variant={{
                    typography: 'heading5',
                  }}
                  $csscolor='00'
                >
                  {item?.title}
                </Text>
              </S.SlideContent>
            </SwiperSlide>
          ) : null;
        })}
      </Swiper>

      {size(youtubeShorts) > 0 ? null : (
        <div className='widget-shorts__skeleton'>{skeletonTemplate}</div>
      )}

      {activeModal ? (
        <S.Modal>
          <Grid.Container>
            <button
              className='widget-shorts__close-modal'
              onClick={hideModal}
            >
              Fechar modal
            </button>

            <Swiper
              speed={500}
              slidesPerView={1}
              initialSlide={currentSlide}
              onSlideChange={swiper => setCurrentSlide(swiper.activeIndex)}
              modules={[Pagination, Navigation, EffectFade]}
              navigation
              pagination={{
                clickable: true,
              }}
              effect='fade'
              className='widget-shorts__slide-modal'
            >
              {limitedShorts?.map((item, index) => (
                <SwiperSlide
                  key={item?.resourceId?.videoId}
                  className='widget-shorts__slide-modal-item'
                >
                  <iframe
                    width='560'
                    height='315'
                    src={
                      index === currentSlide
                        ? `https://www.youtube.com/embed/${item?.resourceId?.videoId}`
                        : ''
                    }
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid.Container>
        </S.Modal>
      ) : null}
    </S.WidgetShortsWrapper>
  );
};

WidgetShorts.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number,
  youtubeShorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(WidgetShorts);
