import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

const closeButton =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPnSURBVHgB1ZrPa9RAFMe/bcVeLMhWKAri9qIoFWoLPXhKPXjSkydP7X/S/hk9tn+A0IP3FgQLgrBSoShIY0UFoXtpYbFQcL67mRpnk8yPvNnGL7wmm81O+sl7mXlvMmOQ1y1lLWU3lE0ru6psyjjnRNmpsjNl35V1lf2AoMYgI8LcVXZH2STC9FvZV2WfIQBZB4wAc5mFwpSJHn2PAWSQQsEeKluAPJCpYEBfMD4ribKbGK0I+DrbOmkC7qKXnii7jtGLkXFP2bmyXy4/cAVbVLak7AouT/xfb2f7P11OtinBwFtNEXtgPhJp1Uk2sASDbrxp4vhYCVcFxvBrkqdMTWfbwrAsAyPQEpovhiWzl6EOZbzgZLp4Af+PGFlmylYI9gwOA+/6+jo2NzcRSx7tMxdNzINm982OYgoOF11bW7v4vLq6CkkFtM+EgY/Pvj5gPmNPYfGWedH5+XnMzs5ie3sbEqrR/oyyAwwG8X9C0clb7XZ76NjKyopIWJpQWsvLy304ixiSc/pD3mNWb1G8c7wI72RedT1XBnV0dIQkSXB4eOjSDGvADnc0GLtN5zFLGk4IiiIPx7UTDcYucxoekoIThMor1WAJ/DL9vurCRYJiP9EhDMPwPgIVChcJiuqHI/+08bccCJIvXEQorWOCPYJA8egKNwIoqkewB3AYv1xkg+N2BFB9cc7jJYTAtDhYc9B2UQwoZN39YwirzHOmIkFRkwRbRATZ4CJC9TWOiErTtPS7nZ2daFBUNI+V9X5a0lWBKYIxqxed0bVBaUWEO2EonkFQVeNUEYBUyWPolB5jgeaVAJfJNvhubGxEKXkK1CUYx7BaKRXlmlHEqucMHRCM8x61JkV906QRwH0gGN9gsMj0Lluo0NwvIhz7jDcahkmw93NWN6GNBPcFuUKTlF7hKJWlR4DbQ25qwCscpUsPQTi+sH/LnTyIrqYrFaueEoKjt465k88VOYtqHayL5hWlElrO+G5tbQ0dd5xXpLc+6Q95j51nVjmmmXdWOkuv0f6FtyjzmeLrGP3G0HrxVqsVpfQIaJ/e2s0fKFo1QKgXGEwZV4oX7na7iCXH9vn4vIKxomCi5ERrSFK9Xg8x5dj+O2XfzINl3bt+Q2jtJS9ZXNzSKfqiatziHDjDUiTzjyCu1tkr+9I2IKdoJhyhdqtOcMk00mzblLBk+O3ZTnLN6BmW7FRmEFgFCIjXZ0fRcTk5ZJHYc2XXMFrxxu7CY5FY6LI+Ltji7FZsQHqJobfv+TvUXWEaC5BAhPmIwcpTb0kvnW3DIWMpEWFSNGDpbJkIyeGBay84X0lvFi12JshxzkQXO/8BGXHvgrgdl7wAAAAASUVORK5CYII=';

const arrow =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM+SURBVHgB1Zo/a9tAGMafOMVdGih1oTQQKi/NkiwxZBae0w/QyVt3fwN/JdPOxl0aKAQH0iWFYtWlLhRcDMlSQyDvI/mCrEiy/t1J+sEb2c7F1i/vq7uz7nZQPPsSLyReSrQkmhJ7gTY3ErcSK4nfEv8k5iiQHRQDZd5KvJF4imz8l/gp8R0FSOYRo8DROrLKRMGMXsCTzERWsWOJExQvFCSzYFoxXiu2xGuYhYKf1sdE7CI5zFJX4jnMw8o4lLiT+JvkD5KKdSROJZ6gPHiuB+vHf5I03oYNL1tVgT0wLwknrtE2MRteN141OD7GysWJsfyqlKkgrfUxtCyjxCh0iurDsuTs5VGH0ghpzBSfoD6wsoJTtlCxM+gfeIuEc1E7+GKwFNlRHKJ+MGMbJRnMWAf1hef+UGl+MWZrD/WFJXmknvjFtGer3W5jMBi4R008DE9qEsxu8wyamU6nsCwLjuOg2+26zzXAyfJcZczI7KLR8D6OcqPRSFfmXBclZsEAtm1jNpt5H6hPzuIPirEMmzAAS8+AHF32KdaCQQzJtVTGjGJAzhUzUoZBNMu5Ys9QEhrlmhzHPiAjPIFer4e8UMj/PkWMc7nE1ICrA8rlyVwDOVADrg7yvneuu068PnSUIq85vnceWIrvUeKsnuXGDkOVtJLKOY+8Yb5XKAlNUuSWYguUgEYpsipFTLMUmRsXMyBFFhTjIpuR68yQFF3m6i4VV1C0z/Ink4luKfJDwlGjYOaVwzSoQVejFHFdVMa4oHaMdOtlqRkOh1gul+j3+7qkuGD/hQ/8K5od1Pu+Ivkscc0H/gnZFUocrAuA2bpWT/xilLpAfdk49+AUmlnbugxaQTayRcK+G4xRr5LkuX4Mvrgb0ZCr8weoB18lfgVfjOre1XKM8TtYKeF1dRn2i7hxi9cav6cZve+YAg7E51G/3DYgO6imHKXGcQ2SzDSc9bEqZcnyO9/WKOkUimXJTuUVNE+7YuDns6O4TNI4yyaxdzB/k5X/2DFSbBLLuq2PC/CcV+oWVLOhK6Qk7w5TXYIUosw3eDtPU1P01lkL2Rc5KOOgAltno6Akhwdu1uT2BGYzbLMzRRa+KHSz8z0MRBTGNnC9DQAAAABJRU5ErkJggg==';

export const WidgetShortsWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.base['50']};
    padding: ${theme.spacings.sm} 0 ${theme.spacings.sm} ${theme.spacings.sm};

    ${breakpointsMedia({
      md: css`
        border-radius: ${theme.borderRadius};
      `,
    })}

    .widget-shorts__slide {
      margin-top: ${theme.spacings.sm};

      .widget-shorts__slide-item {
        width: 10.8rem;

        &:last-of-type {
          margin-right: ${theme.spacings.sm};
        }
      }
    }

    .widget-shorts__skeleton {
      width: 100%;
      display: flex;
      overflow-x: auto;
    }
  `}
`;

export const SlideContent = styled.div`
  ${({ theme, slideImage }) => css`
    width: 100%;
    height: 19.2rem;
    position: relative;
    border-radius: ${theme.borderRadius};
    background: url(${slideImage}) lightgray 50% / cover no-repeat;

    &::after {
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 9.6rem;
      position: absolute;
      border-bottom-left-radius: ${theme.borderRadius};
      border-bottom-right-radius: ${theme.borderRadius};
      background: linear-gradient(
        180deg,
        rgba(27, 35, 58, 0) 0%,
        ${theme.colors.base['900']} 100%
      );
    }

    p {
      z-index: 1;
      position: absolute;
      left: ${theme.spacings.sm};
      right: ${theme.spacings.sm};
      bottom: ${theme.spacings.sm};
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
    }
  `}
`;

export const Modal = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(53, 53, 53, 0.95);
    top: 0;
    left: 0;
    z-index: 15;

    button {
      border: 0;
      font-size: 0px;
      width: 3.6rem;
      height: 3.6rem;
      display: block;
      cursor: pointer;
      border-radius: 50%;
      color: transparent;
      background: transparent;
      background-size: contain;

      &.widget-shorts__close-modal {
        margin-left: auto;
        margin-top: ${theme.spacings.xxl};
        background-image: url(${closeButton});
      }
    }

    .widget-shorts__slide-modal {
      width: 57rem;
      max-width: 100%;
      position: relative;
      overflow-y: auto;
      padding: ${theme.spacings.lg} 0 0;
      margin: ${theme.spacings.md} auto 0;

      ${breakpointsMedia({
        lg: css`
          padding: ${theme.spacings.lg} 5rem 0;
        `,
      })}

      iframe {
        width: 100%;
        height: 85rem;
        max-height: 80vh;
        z-index: 5;
      }

      .swiper-button-prev {
        left: 0;
        transform: rotate(180deg);
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev,
      .swiper-button-next {
        z-index: 10;
        width: 3.6rem;
        height: 3.6rem;
        cursor: pointer;
        color: transparent;
        border-radius: 50%;

        ${breakpointsMedia({
          lg: css`
            display: block;
          `,
        })}

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background-size: contain;
          background-image: url(${arrow});
        }
      }

      .swiper-pagination {
        top: 0;
        padding: 0;
        bottom: auto;
        display: flex;

        ${breakpointsMedia({
          lg: css`
            padding: 0 5rem;
          `,
        })}

        .swiper-pagination-bullet {
          flex: 1;
          height: 0.4rem;
          border-radius: 1.6rem;
          background-color: ${theme.colors.base['500']};

          &.swiper-pagination-bullet-active {
            background-color: ${theme.colors.base['50']};
          }
        }
      }
    }
  `}
`;
